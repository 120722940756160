<template>
    <div class="container">
        <banner></banner>
        <div class="center-content" style="padding-top: 60px">
            <menu-page>
                <div class="menu-content" slot="menuList">
                    <el-menu :default-active="active" class="menu-content" unique-opened router>
                        <sys-menu v-for="item in menus" :menu="item" :key="item.id"></sys-menu>
                    </el-menu>
                </div>

                <div class="main">
                    <filter-list></filter-list>
                </div>
            </menu-page>
        </div>
    </div>
</template>

<script>
import FilterList from '../../components/page/FilterList.vue';
import Banner from '../../components/Banner.vue';
import MenuPage from '../../components/page/MenuPage.vue';
import menu from '../../mixins/menu';
import SysMenu from '../../components/SysMenu.vue';
export default {
    components: { MenuPage, Banner, FilterList, SysMenu },
    mixins: [menu],
    mounted() {
        this.$http
            .post('navigation/backAll', {
                type: 'FUSION_DATA'
            })
            .then(res => {
                this.menus = res;
                this.findActive();
            });
    }
};
</script>

<style lang="less" scoped>
.container {
    background-color: @bg;
}

.main {
    min-height: 1080px;
    flex-grow: 1;
    .flex-col();
}

.menu-content {
    min-height: 800px;
    background-color: #fff;
}
</style>
